import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ChartComponent } from './ChartComponent';
import EmailIcon from '@mui/icons-material/Email';
import IconButton from '@mui/material/IconButton';
import TelegramIcon from '@mui/icons-material/Telegram';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
        dotINSolution 
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export function App(props) {
	return (
      <React.Fragment>
        <CssBaseline />
          <Container maxWidth="false">
            <br/>
            <Grid container spacing={2}>
              <Grid xs={12}>
                <AppBar position="static">
                  <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                      Tradetron Intraday PNL Snapshot
                    </Typography>
                    <IconButton size="large" color="inherit">
                      <TelegramIcon />
                      <Button color="inherit" href={`https://bit.ly/3SkhTti`} target="_blank">Telegram Discussion Group</Button>
                    </IconButton>
                    <IconButton size="large" color="inherit">
                      <EmailIcon />
                      <Button color="inherit" href={`https://bit.ly/3lRtAex`} target="_blank">Contact for Custom Algo Trading Software Development (Python/GoLang)</Button>
                    </IconButton>
                  </Toolbar>
                </AppBar>
              </Grid>
              <Grid xs={12}>
		            <ChartComponent></ChartComponent>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
      </React.Fragment>
	);
}

export default App;
