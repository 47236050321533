import { createChart, ColorType } from 'lightweight-charts';
import React, { useEffect, useRef } from 'react';

const BACKEND_SERVER_URL = process.env.REACT_APP_BACKEND_SERVER_URL;

export const ChartComponent = props => {
  const {
    colors: {
      backgroundColor = 'white', textColor = 'black',
    } = {},
  } = props;

  const chartContainerRef = useRef();

  useEffect(
    () => {
      const handleResize = () => {
        chart.applyOptions({ width: chartContainerRef.current.clientWidth });
      };

      const chart = createChart(chartContainerRef.current, {
        layout: {
          background: { type: ColorType.Solid, color: backgroundColor },
          textColor,
        },
        width: chartContainerRef.current.clientWidth,
        height: 800,
        timeScale: {
          timeVisible: true,
          secondsVisible: false,
          fixLeftEdge: true,
          fixRightEdge: true
        },
      });

      const baselineSeries = chart.addBaselineSeries({
        baseValue: { type: 'price', price: 0 },
        topLineColor: 'rgba( 38, 166, 154, 1)',
        topFillColor1: 'rgba( 38, 166, 154, 0.28)',
        topFillColor2: 'rgba( 38, 166, 154, 0.05)',
        bottomLineColor: 'rgba( 239, 83, 80, 1)',
        bottomFillColor1: 'rgba( 239, 83, 80, 0.05)',
        bottomFillColor2: 'rgba( 239, 83, 80, 0.28)'
      });

      let params = (new URL(document.location)).searchParams;
      let execution_id = params.get("execution_id");

      fetch(`${BACKEND_SERVER_URL}?execution_id=${execution_id}`).then(response => response.json()).then(response => {
        baselineSeries.setData(response['pnl_snapshot']);
        chart.timeScale().fitContent();
      });


      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
        chart.remove();
      };
    },
    [backgroundColor, textColor]
  );

  return (
    <div
      ref={chartContainerRef} />
  );
};
